<template>
  <div class="standalone-hosting-form">
    <form ref="standalone-hosting-form">
      <validation-observer ref="observer">
        <b-row class="px-4 mt-4">
          <b-col>
            <label>Domain</label>
            <validation-provider name="domain" rules="required">
              <b-form-input
                v-model="standaloneHostingRequest.domainName"
                type="text"
                :state="errorState('domain')"
                @blur="inputDirty.provider = true"
              />
              <b-form-invalid-feedback id="domain-error-feedback">
                Domain is required
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="px-4">
          <b-col>
            <span class="text--error">
              {{ errorMessage }}
            </span>
          </b-col>
        </b-row>
        <b-row class="px-4 py-3">
          <b-col cols="auto">
            <div>Want to talk to a person about starting?</div>
            <b-checkbox
              v-model="standaloneHostingRequest.requestCall"
              class="text--teal"
              @click.native="inputDirty.requestCall = true"
            >
              Request a call
            </b-checkbox>
          </b-col>
          <b-col />
          <b-col cols="auto">
            <b-button
              v-if="cancellable"
              class="mr-2"
              variant="outline-danger"
              aria-label="Cancel button"
              :disabled="loading"
              @click="closeModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :aria-label="submitText + 'button'"
              :disabled="loading"
              @click="submitRequest"
            >
              <b-overlay :show="loading" rounded="sm" :opacity="0" variant="black">
                <span>{{ submitText }}</span>
              </b-overlay>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </form>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'StandaloneHostingForm',
  mixins: [
    makeToastMixin,
  ],
  props: {
    cancellable: {
      type: Boolean,
      default: false,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      showPassword: false,
      standaloneHostingRequest: {
        domainName: '',
        requestCall: false,
      },
      inputDirty: {
        domainName: '',
        requestCall: false,
      },
    }
  },
  computed: {
    ...mapGetters('domains', [
      'standaloneHostingInfo',
    ]),
    passwordInputType() {
      return this.showPassword ? 'text' : 'password'
    },
    submitText() {
      return this.standaloneHostingRequest.requestCall ? 'Request a Call' : 'Activate Hosting'
    },
    companyId() {
      return this.$route.params.companyId
    },
    passwordIconBorder() {
      const passwordState = this.errorState('password')
      if(passwordState === null) return null
      return passwordState ? 'border-valid' : 'border-invalid'
    },
    formIsPristine(){
      return Object.values(this.inputDirty).every(dirty => !dirty)
    },
  },
  created() {
    if (this.standaloneHostingInfo?.id) this.standaloneHostingRequest = { ...this.standaloneHostingInfo }
  },
  methods: {
    ...mapActions('domains', [
      'submitStandaloneHostingRequest',
      'updateStandaloneHostingRequest',
      'fetchStandaloneHostingRequest',
    ]),
    errorState(field) {
      if (!this.inputDirty[field] || this.standaloneHostingRequest.requestCall) return null
      return this.standaloneHostingRequest[field]?.length > 0
    },
    async validate() {
      for (const field in this.inputDirty) this.inputDirty[field] = true
      return await this.$refs.observer.validate()
    },
    async submitRequest() {
      this.loading = true

      try {
        if (this.standaloneHostingRequest.id && this.formIsPristine) return await this.closeModal()
        if (this.standaloneHostingRequest.requestCall) return await this.submitApiStandaloneHostingRequest()
        if (!await this.validate() || this.errorMessage) return
        await this.submitApiStandaloneHostingRequest()
      } catch {
        this.errorToast('Submission Error', 'There was an error submitting your request.')
      } finally {
        this.loading = false
        this.$emit('next-slide')
      }
    },
    async submitApiStandaloneHostingRequest() {
      this.errorMessage = null
      const payload = {
        ...this.standaloneHostingRequest,
        companyId: this.companyId,
      }
      const result = this.standaloneHostingRequest.id
        ? await this.updateStandaloneHostingRequest(payload)
        : await this.submitStandaloneHostingRequest(payload)
      if (result.data?.status === 200) {
        await this.bus.$emit('log-domain-interaction', { name: 'standalone-hosting-request' })

        const toastMessage = this.standaloneHostingRequest.requestCall ?
          'Call request submitted!' :
          'Standalone hosting request submitted!'

        await this.fetchStandaloneHostingRequest({ companyId: this.companyId })
        this.successToast('Success', toastMessage)
        await this.closeModal()
      } else {
        this.errorMessage = result.data?.response?.error?.split(':')[1]
      }
    },
    async closeModal() {
      this.standaloneHostingRequest = {
        domainName: '',
        requestCall: false,
      }
      this.inputDirty = {
        domainName: '',
        requestCall: false,
      }
      this.$emit('closeModal')
    },
  },
}
</script>


<style scoped lang='scss'>
.standalone-hosting-form {

  .text--teal {
    color: $ct-ui-color-10;
  }

  .text--error {
    color: #BC2F2F;
  }

  .password-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: lightgray;
    height: 60px;
    width: 60px;
    border: 1px solid gray;
    border-radius: 0 4px 4px 0;
  }

  .border-valid {
    border-color: green;
  }

  .border-invalid {
    border-color: #BC2F2F;
  }

  .show-password-icon {
    width: 25px;
    height: 25px;
  }

  .btn {
    border-radius: 4px;
  }

  .submit-btn {
    width: 200px;
  }

  .error-message {
    color: #BC2F2F;
  }
}
</style>
